import React, { useEffect, useRef, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';

import AmenityPopup from './AmenityPopup';
import { mediaUrl, viewerUrl } from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAmenity, bulkAmenitiesUpdate } from 'shared/utils/Explorer3dApi';
import { setAmenities } from 'redux/actions';
import { cloneDeep } from 'lodash';

function Amenities({ isFullScreen }) {
  const dispatch = useDispatch();

  const mainFrameRef = useRef(null);
  const [selectedAmenity, setSelectedAmenity] = useState(null);
  const [showButton, setShowButton] = useState(true);
  const [showSettingsPopup, setShowSettingsPopup] = useState(false);
  const [newAmenity, setNewAmenity] = useState(null);
  const [editingAmenity, setEditingAmenity] = useState(null);

  const amenitiesBackend = useSelector(
    state => state.explorer3d.current.amenities
  );

  const [selectedAmenityIframeUrl, setSelectedAmenityIframeUrl] = useState(null);
  useEffect(() => {
    if (selectedAmenity?.link) {
      const url = new URL(selectedAmenity.link);
      url.searchParams.set('preset', '3dexplorer');
      setSelectedAmenityIframeUrl(url.toString());
    } else {
      setSelectedAmenityIframeUrl(null);
    }
  }, [selectedAmenity]);

  const explorer3d = useSelector(state => state.explorer3d.current);

  const amenities = newAmenity
    ? [...amenitiesBackend.map(a => ({ ...a })), newAmenity]
    : amenitiesBackend.map(a => ({ ...a }));

  amenities.sort((a, b) => a.index - b.index);

  const handleClick = () => {
    setShowButton(false);
    setShowSettingsPopup(true);
    setNewAmenity({
      id: uuidv4(),
      isNew: true,
      index: amenitiesBackend.length,
      name: 'New Amenity',
      tourType: 'text',
      localTourId: null,
      externalTourLink: '',
      icon: '',
    });
    setEditingAmenity(null);
  };

  const handleClose = () => {
    setShowButton(true);
    setShowSettingsPopup(false);
    setEditingAmenity(null);
    setNewAmenity(null);
  };

  const handleEdit = (e, amenityId) => {
    e.stopPropagation();
    const amenityToEdit = amenities.find(amenity => amenity.id === amenityId);
    const icon = amenityToEdit.icon.split('/').at(-1).split('.')[0];
    setEditingAmenity({
      ...amenityToEdit,
      tourType: amenityToEdit.link.startsWith(viewerUrl) ? 'text' : 'textarea',
      localTourId: amenityToEdit.link.startsWith(viewerUrl)
        ? +amenityToEdit.link.slice(viewerUrl.length + 1).split('=')[1]
        : null,
      icon,
    });
    setShowSettingsPopup(true);
  };

  const handleDelete = async (e, id) => {
    try {
      e.stopPropagation();
      const res = await deleteAmenity({ id });
      dispatch(setAmenities(res));
    } catch (e) {
      console.error(e);
    }
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
  
    // Deep clone amenities to avoid referencing original objects
    const items = amenities.map(item => ({ ...item }));
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
  
    // Reassign indices to ensure they are sequential and unique
    items.forEach((item, index) => {
      item.index = index;
    });
  
    bulkAmenitiesUpdate({ id: explorer3d.id, amenities: items });
    dispatch(setAmenities(items));
  };
  

  function getRightSidebarRender() {
    if (!amenitiesBackend.length) {
      return (
        <div className="add-amenity-text">
          ← Add the first amenity <br />
          to the panel
        </div>
      );
    } else if (selectedAmenityIframeUrl) {
      return (
        <iframe
          src={selectedAmenityIframeUrl}
          style={{ width: '100%', height: '100%' }}
          frameBorder={0}
        />
      );
    }
  }

  function getIconSrc(icon) {
    if (typeof icon === 'string') {
      return `${process.env.PUBLIC_URL}/img/${icon || 'floors-gray'}.svg`;
    } else if (icon instanceof File) {
      return icon.preview;
    }
  }

  function selectAmenityHandler(a) {
    return () => {
      setSelectedAmenity(a);
    };
  }

  return (
    <Container
      className="d-flex flex-row amenities-tab no-padding"
      fluid={isFullScreen}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="amenities">
          {provided => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="d-flex flex-column left-sidebar"
            >
              <div className="d-flex align-items-center justify-content-between header">
                <p>AMENITIES</p>
                {showButton && (
                  <Button
                    className="d-flex align-items-center justify-content-center"
                    variant="primary"
                    onClick={handleClick}
                    type="submit"
                    size="sm"
                  >
                    ADD NEW
                    <img
                      src={`${process.env.PUBLIC_URL}/img/plus-img.svg`}
                      alt="plus-img"
                      className="ml-1"
                    />
                  </Button>
                )}
              </div>
              {amenities.map((amenity, index) => (
                <Draggable
                  key={amenity.id.toString()}
                  draggableId={amenity.id.toString()}
                  index={index}
                >
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      onClick={selectAmenityHandler(amenity)}
                      className={`d-flex align-items-center justify-content-between amenity ${selectedAmenity?.id === amenity.id ? 'amenity-selected' : ''}`}
                    >
                      <div className="d-flex align-items-center justify-content-center h-100">
                        <img
                          src={
                            !amenity.isNew
                              ? mediaUrl + amenity.icon
                              : getIconSrc(amenity.icon)
                          }
                          alt="amenity-icon"
                          className="amenity-icon"
                        />
                        <p>{amenity.name}</p>
                      </div>
                      <div className="d-flex align-items-end justify-content-center button-section h-100">
                        <Button
                          className="edit-button d-flex align-items-center justify-content-center h-100"
                          onClick={e => handleEdit(e, amenity.id)}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/img/edit-pencil.svg`}
                            alt="edit-pencil"
                          />
                        </Button>
                        <Button
                          className="delete-button d-flex align-items-center justify-content-center h-100"
                          onClick={e => handleDelete(e, amenity.id)}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/img/trash.svg`}
                            alt="trash"
                          />
                        </Button>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <div className="right-sidebar d-flex flex-row" ref={mainFrameRef}>
          {showSettingsPopup && (
            <AmenityPopup
              currentAmenity={editingAmenity}
              onClose={handleClose}
            />
          )}
          {getRightSidebarRender()}
        </div>
      </DragDropContext>
    </Container>
  );
}

export default Amenities;
