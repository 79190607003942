import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactReduxContext } from 'react-redux';

import API from '../../../../shared/utils/API';
import { createToast, hidePopup, showPopup } from '../../../../redux/actions';
import { locationNameRegex } from '../../../AddLocation/components/validate';
import { createExplorer3d } from 'shared/utils/Explorer3dApi';

const initialValues = {
  STATUS: 'ACTIVE',
  LOCATION_AREA_SHOW: true,
  LOCATION_SHORT_INFO: '',
  LOCATION_POINT_SHORT_INFO: [],
  INSTANT_PANO_LOCATION_POINT_SHORT_INFO: [],
  LOCATION_PRIORITY: 1,
  INITIAL_LOCATION_POINT_SHORT_INFO: [],
  INITIAL_INSTANT_PANO_LOCATION_POINT_SHORT_INFO: [],
  START_POINT: 1,
  POINT_COUNT: 0,
  INSTANT_PANO: true,
  SHOW_MAP: true,
  SHOW_MEASURE_DISTANCE: true,
  SHOW_STATS: false,
  USE_256_RESOLUTION: false,
  USE_4K_TILES: true,
  USE_EQUIRECTANGULAR: true,
  PRELOAD_CAMERA_DIRECTION: true,
  USE_TILED_CUBEMAPS: false,
  USE_BASIS: false,
  IS_MAIN: false,
  LIMIT_MOVEMENT_TO_NEAREST_POINTS: false,
  AUTOFOLLOW: true,
  AUTOROTATION: true,
  IS_DOLL_HOUSE_ENABLED: false,
  IS_ARROWS_ENABLED: false,
  IS_ARROWLESS_NAVIGATION_ENABLED: true,
  IS_3D_TOUR_TRIGGERS_ENABLED: true,
  IS_FLOOR_POINTS_ENABLED: true,
  IS_CURSOR_POINTS_ENABLED: false,
  MEASURE_USE_FEET: true,
  IS_INTERACTIVE_PLAN: false,
  INTERACTIVE_FLOORPLAN_ID: null,
  HIDE_SPLASH_POWERED_BY_VIEW_VR: false,
};

const initialValuesInteractiveFloorPlan = {
  FLOOR_PLAN_AUTOROTATION: false,
  IS_INTERACTIVE_PLAN: false,
  FRAMES_UPLOADED: false,
  USE_CUSTOM_LEVELS_NAMES: false,
  TOUR: '',
  TOUR_TEXT: '',
  LEVELS: [],
};

export const useCreateLocation = (company, multilocation, multilocatonData) => {
  const history = useHistory();
  const { store } = useContext(ReactReduxContext);
  const dispatch = store.dispatch;

  return () =>
    dispatch(
      showPopup({
        onSubmit: async values => {
          try {
            if (values.tourType === '3dExplorer') {
              const res = await createExplorer3d({
                name: values.locationName,
                multilocationId: multilocatonData.id,
              });
              history.push(`/explorer3ds/${res.locationId}`);
            } else {
              const res = await API.request(API.endpoints.ADD_LOCATION, {
                companyName: decodeURIComponent(company),
                multilocationName: decodeURIComponent(multilocation),
                name: values.locationName,
                description: `'${JSON.stringify({
                  ...initialValues,
                  LOCATION_NAME: values.locationName,
                  COMPANY_NAME: decodeURIComponent(company),
                  LIST_ID: decodeURIComponent(multilocation),
                  INSTANT_PANO_ONLY: values.tourType === '2D Tour',
                  IS_INTERACTIVE_PLAN:
                    values.tourType === 'Interactive Floor Plan',
                })}'`,
              });
              history.push(`/location/${res.result.id}`);
            }
          } catch (e) {
            dispatch(createToast('ERROR', 'Something went wrong ' + e.message));
            console.error(e);
          } finally {
            dispatch(hidePopup());
          }
        },
        label: {
          text: 'Please enter location name',
          color: 'black',
        },
        submitButton: {
          text: 'Create',
          variant: 'success',
        },
        validate: data => {
          const errors = {};
          if (locationNameRegex.test(data.locationName)) {
            errors.locationName = 'Field contains forbidden characters';
          }
          if (!data.locationName.trim()) {
            errors.locationName = 'Required field';
          }
          if (!data.tourType) {
            errors.tourType = 'Required field';
          }

          return errors;
        },
        enableReinitialize: true,
        initialValues: {
          tourType: '',
          locationName: '',
        },
        formData: [
          {
            type: 'input',
            name: 'locationName',
            props: {
              placeholder: 'Location name',
              type: 'text',
              onChangeValidate: true,
            },
          },
          {
            type: 'select',
            name: 'tourType',
            props: {
              options: [
                { name: 'Choose tour type', value: '' },
                { name: '3D Tour', value: '3D Tour' },
                { name: '2D Tour', value: '2D Tour' },
                { name: '3D Explorer', value: '3dExplorer' },
                {
                  name: 'Interactive Floor Plan',
                  value: 'Interactive Floor Plan',
                },
              ],
            },
          },
        ],
      })
    );
};
